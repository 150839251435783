import { dev } from "$app/environment";
import * as Sentry from "@sentry/sveltekit";

Sentry.init({
  dsn: "https://ac8790ebfbb6355526a4a2dadf02345f@o4507804085452800.ingest.de.sentry.io/4507809547092048",
  enabled: !dev,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,

  // Optional: Initialize Session Replay:
  integrations: [Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const sentryErrorHandler = ({ error, event }) => {
  console.error("An error occurred on the client side:", error, event);
};

export const handleError = Sentry.handleErrorWithSentry(sentryErrorHandler);

// or alternatively, if you don't have a custom error handler:
// export const handleError = handleErrorWithSentry();
