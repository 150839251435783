import type { ParamMatcher } from "@sveltejs/kit";
import { locale } from "$lib/services/i18n";

let currentLocale = "en";

locale.subscribe(value => {
  currentLocale = value;
});

export const match: ParamMatcher = function match(param) {
  return (OPTIONS.get(currentLocale) || new Set()).has(param);
};

/*dynamic-update-start*/
const OPTIONS = new Map<string, Set<string>>([
["en",new Set<string>(["premier-league","la-liga","bundesliga","ligue-1","champions-league-tickets","europa-league","fa-cup","capital-one-cup","copa-del-rey","international","serie-a","community-shield","uefa-super-cup","fifa-club-world-cup-2018","spanish-super-cup","coupe-de-france","trophee-des-champions","dfb-pokal","dfb-supercup","coppa-italia","supercoppa-italiana","primeira-liga","eredivisie","coupe-de-la-ligue","emirates-cup","champions-league-play-offs","europa-league-play-offs","europa-league-round-of-32","spfl","carabao-cup","rbs-six-nations","scottish-cup","uefa-nations-league","championship-play-off-final","international-champions-cup","checkatrade-trophy","championship-play-offs","league-one-play-offs","friendly","euro-2021","club-world-cup","fifa-world-cup-qualifier","uefa-women-s-euro","uefa-women-s-champions-league","finalissima-2022","fifa-world-cup-2022","uefa-europa-conference-league","dubai-super-cup","scottish-premiership","women-s-finalissima","uefa-euro-2024","papa-johns-trophy","fa-cup-womens","vanarama-national-league","fa-vase","fa-trophy","uefa-euro-2024-qualifiers","events-other-test","copa-america","championship","fa-cup-women-s","olympic-game"])],
]);