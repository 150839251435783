import type { ParamMatcher } from "@sveltejs/kit";

const LANGUAGE_OPTIONS = new Set([
  "zh",
  "cz",
  "dk",
  "nl",
  "en",
  "fi",
  "fr",
  "de",
  "is",
  "it",
  "ja",
  "kr",
  "no",
  "pl",
  "pr",
  "ru",
  "es",
  "sv",
  "th",
  "tr",
]);

export const match: ParamMatcher = function match(param) {
  // return param === "en";
  return LANGUAGE_OPTIONS.has(param);
};
